jQuery(document).ready(function ($) {
    var clicker = false;

    $('.prevent-click > a').click(function (e) {
        e.preventDefault();
    })

    function slider() {
        $('#testimonial').slick({
            dots: true,
            infinite: true,
            speed: 250,
            fade: true,
            cssEase: 'linear',
            arrows: false,
            rtl: !!$('body.rtl').length
        });
    }

    function closeAll() {
        $('body').removeClass('stop');
        $('#menuButton').removeClass('open');
        $('.menu__center').removeClass('open');
        clicker = false;
    }

    function menu() {

        $('#menuButton').click(function (e) {
            e.preventDefault();
            if (!clicker) {
                $('body').addClass('stop');
                $(this).addClass('open');
                $('.menu__center').addClass('open');
                clicker = true;
            } else {
                closeAll();
            }
        })
    }

    function mobileMenu() {
        $('#menu-menu a').click(function () {
            closeAll();
        })
    }

    function ancore() {
        $(function () {
            $('a[href*="#"]:not([href="#"])').click(function () {
                if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                    var target = $(this.hash);
                    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                    if (target.length) {
                        $('html, body').animate({
                            scrollTop: target.offset().top
                        }, 1000);
                        return false;
                    }
                }
            });
        });
    }

    function videoState() {
        var vid = document.getElementById("video");

        $('#play').click(function () {
            $('#pause').removeClass('hide');
            $(this).addClass('hide');
            vid.play();
        });

        $('#pause').click(function () {
            vid.pause();
            $(this).addClass('hide');
            $('#play').removeClass('hide');
        });

        if (vid) {
            vid.addEventListener('ended', returnToStart, false);
        }

        function returnToStart() {
            $('#pause').addClass('hide');
            $('#play').removeClass('hide');
        }
    }

    videoState();
    mobileMenu();
    ancore();
    slider();
    menu();

    setTimeout(function () {
        $('#preloader').fadeOut();
    }, 300)

});